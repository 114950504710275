import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import InputField from "./Inputs/InputField";
import InputFieldArray from "./Inputs/InputFieldArray";
import SelectField from "./Inputs/SelectField";

import InputCheckBoxField from "./Inputs/InputCheckBoxField";
import InputCurrencyField from "./Inputs/InputCurrencyField";
import InputDateField from "./Inputs/InputDateField";
import InputPasswordField from "./Inputs/InputPasswordField";
import InputSimplePassword from "./Inputs/InputSimplePassword";
import { NavLink } from "react-router-dom";
import { useGetMasterRegistrationDataQuery } from "../../services/registrationApi";
import { useSelector } from "react-redux";
import GoogleAutoComplete from "../common/googleAutocomplete/googleAutocomplete";
import moment from "moment";
import { logout_url, protocol } from "../../config";
import { ImCross } from "react-icons/im";
import SupportSection from "../common/SupportSection/SupportSection";
import { useDispatch } from "react-redux";
import { JSONTree } from "react-json-tree";

const RegistrationFormBody = (props) => {
  const [nav, setNav] = useState(0);
  const [nextMonthDate, setNextMonthDate] = useState();
  const [clickHere, setClickHere] = useState(true);
  const [showAnotherPhone, setShowAnotherPhone] = useState(false);
  const dispatch = useDispatch();
  const useWindowWidth = useSelector(
    (state) => state.identification.sideMenuSlice
  );

  const trn_id = useSelector(
    (state) => state.persistedReducer.loginSlice.trnId
  );
  const url = useSelector(
    (state) => state.persistedReducer.loginSlice.urlGeneratedAts
  );
  const applicationType = useSelector(
    (state) => state.persistedReducer.loginSlice.applicationType
  );

  const { data, isLoading, isError, isSuccess } = useGetMasterRegistrationDataQuery({
    trn_id,
    url,
  });

  const getUnitsList = (floorId) => {
    if (!floorId) return [];
    const unitsFilter = data.records.units.filter((item) => {
      return item.FloorPlanID === floorId;
    });

    const units = unitsFilter.map((item, index) => {
      console.log(">>>>", item)
      return {
        value: item.ID || index,
        label: `Unit: ` + item.UnitName + ` (Rent: $` + item.MaximumRent + ')',
      };
    });

    if (units.length) return units;
    return [];
  };

  const { register, watch, trigger, formState, getValues, setValue } =
    useFormContext();
  const handleDependTrigger = (e) => {
    trigger("password");
  };
  const handleDependTriggerCPass = (e) => {
    trigger("reEnterPassword");
  };
  const handleDependTriggerEmail = (e) => {
    trigger("email");
  };
  const handleDependTriggerCEmail = (e) => {
    trigger("reEmail");
  };

  const getNextMonthDate = () => {
    let date = new Date();
    let nextDate = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    setNextMonthDate(nextDate);
  };

  useEffect(() => {
    getNextMonthDate();
  }, []);
  useEffect(() => {
    document.title = "Docuverus";
  }, []);

  const myTextStyles = {
    color: 'var(--Primary-CTA, #2DC3E8)',
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
  };
  const property_rent = watch("unit")?.label?.split("Rent: $")[1]?.replace(")", "")
    ? watch("unit")?.label?.split("Rent: $")[1]?.replace(")", "")
    : watch("floor")?.label?.label?.split("Rent: $")[2]?.replace(")", "")
  if (isLoading && isError) return null;
  else
    return (
      <section className="application-body-wrap mr-b30">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="text-center">
                <img
                  className="mb-3"
                  src={`${data?.records?.property?.LogoURL || data?.records?.Office?.LogoURL}`}
                  style={{ maxHeight: "180px" }}
                  alt={data?.records.subscriber_name}
                  title={data?.records.subscriber_name}
                />
                <p>
                  Thanks for your interest in applying with{" "}
                  {data?.records?.property?.PropertyName ||
                    data?.records.subscriber_name}
                </p>
                {Number(property_rent) > 0 && <>
                  {!watch("unit")?.label && <strong>
                    {watch("floor")?.label &&
                      `${watch("floor")?.label?.split("&").join()}`}
                  </strong>}
                  <strong>
                    {watch("unit")?.label &&
                      `${watch("unit")?.label}`}
                  </strong>
                </>}
              </div>

              <div className="app-title text-center mr-b30 mr-t10">
                <h3 className="mr-b10">
                  Please register to start your application
                </h3>
                <h5>
                  Already started application?{" "}
                  <a style={myTextStyles} onClick={(e) => { e.preventDefault(); window.location.replace(logout_url) }}>Login Here</a>
                </h5>
                <br />
              </div>
              <div className="registretion-form-wrap">
                <div className="row">
                  <div className="col-sm-12 col-md-4 col-lg-3">
                    <div
                      className={`${useWindowWidth.windowWidth < 992
                        ? "mb-2 d-none"
                        : "show-sidebar tabs-nav-block white-box "
                        }`}
                    >
                      <div className="s-tab-list">
                        <ul className="nav nav-tabs flex-column">
                          <li
                            className={`nav-item mr-b0 ${nav === 0 && "site-active"
                              }`}
                          >
                            <a
                              className={`nav-link ${nav === 0 && "active"}`}
                              onClick={(e) => {
                                setNav(0);
                              }}
                              href="#personalDetail"
                            >
                              Personal Details
                            </a>
                          </li>
                          {applicationType == "Property" ||
                            applicationType === "Floor" || applicationType === "Unit" ? (
                            <li
                              className={`nav-item mr-b0 ${nav === 4 && "site-active"
                                }`}
                            >
                              <a
                                className={`nav-link ${nav === 4 && "active"}`}
                                onClick={(e) => {
                                  setNav(4);
                                }}
                                href="#floorAndUnit"
                              >
                                Floor and Unit Plan
                              </a>
                            </li>
                          ) : null}
                          {applicationType == "Office" || applicationType == "Agent" ? (
                            <li
                              className={`nav-item mr-b0 ${nav === 1 && "site-active"
                                }`}
                            >
                              <a
                                className={`nav-link ${nav === 1 && "active"}`}
                                onClick={(e) => {
                                  setNav(1);
                                }}
                                id="agent-tab"
                                href="#agent"
                              >
                                Agent
                              </a>
                            </li>
                          ) : null}
                          {applicationType == "Office" || applicationType == "Agent" ? (
                            <li
                              className={`nav-item mr-b0 ${nav === 2 && "site-active"
                                }`}
                            >
                              <a
                                className={`nav-link ${nav === 2 && "active"}`}
                                onClick={(e) => {
                                  setNav(2);
                                }}
                                id="desired-tab"
                                href="#desired"
                              >
                                Desired Property Details
                              </a>
                            </li>
                          ) : null}
                          <li
                            className={`nav-item mr-b0 ${nav === 3 && "site-active"
                              }`}
                          >
                            <a
                              className={`nav-link ${nav === 3 && "active"}`}
                              onClick={(e) => {
                                setNav(3);
                              }}
                              id="logind-tab"
                              href="#logind"
                            >
                              Login Details
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-12 col-lg-9">
                    <div className="left-tabs-info">
                      {/* applicaion-form-wrap */}
                      <div className="">
                        <div
                          onFocus={() => {
                            setNav(0);
                          }}
                          className="form-w-block white-box mr-b30"
                          id="personalDetail"
                        >
                          <div className="row form-inputs">
                            <div className="col-sm-12 col-md-4 col-lg-4">
                              <div className="form-group cus-input-group mr-b30">
                                <InputField
                                  label="First Name"
                                  register={register("fname")}
                                />
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4">
                              <div className="form-group cus-input-group mr-b30">
                                <InputField
                                  notRequired={true}
                                  label="Middle Name"
                                  register={register("mname")}
                                />
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4">
                              <div className="form-group cus-input-group mr-b30">
                                <InputField
                                  label="Last Name"
                                  register={register("lname")}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row ">
                            <div className="col-sm-12 col-md-8 col-lg-8 blue-in-box d-flex">
                              <div
                                style={{
                                  flex: 1,
                                }}
                                className="register-form-group"
                              >
                                <div
                                  id="mobile"
                                  tabIndex="0"
                                  className="form-group cus-input-group register-mobile-input"
                                >
                                  <InputFieldArray
                                    disabled={true}
                                    showNumberType={false}
                                    addMultipleNumber={false}
                                    register={register}
                                    rigesterKey={"mobile"}
                                  />
                                </div>
                              </div>
                              <div className="mt-4" style={{ flex: 0.4 }}>
                                {!showAnotherPhone && (
                                  <div className=" ms-2 ">
                                    <button
                                      className="add-num-number"
                                      type="button"
                                      onClick={() => {
                                        setValue(`other`, null);

                                        setShowAnotherPhone(true);
                                      }}
                                    >
                                      <svg
                                        width="14"
                                        height="14"
                                        viewBox="0 0 13 13"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M5.68652 5.44287V0.442871H7.35319V5.44287H12.3532V7.10954H7.35319V12.1095H5.68652V7.10954H0.686523V5.44287H5.68652Z"
                                          fill="#2DC3E8"
                                        />
                                      </svg>
                                      &nbsp;
                                      <span
                                        className={`${useWindowWidth.windowWidth < 766
                                          ? "font-10"
                                          : ""
                                          }`}
                                      >
                                        Add another number
                                      </span>
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          {showAnotherPhone && (
                            <div className="row">
                              <div className="col-sm-12 col-md-8 col-lg-8 blue-in-box d-flex">
                                <div
                                  style={{ flex: 1 }}
                                  className="register-form-group"
                                >
                                  <div
                                    id="mobile"
                                    tabIndex="0"
                                    className="form-group cus-input-group register-mobile-input"
                                  >
                                    <InputFieldArray
                                      showNumberType={true}
                                      addMultipleNumber={false}
                                      register={register}
                                      rigesterKey={"other"}
                                      notRequired={true}
                                    />
                                  </div>
                                </div>
                                <div
                                  style={{ flex: 0.3 }}
                                  className="ms-4 mt-4"
                                >
                                  <ImCross
                                    className="cursor-pointer"
                                    size={15}
                                    fill={"#2DC3E8"}
                                    onClick={() => setShowAnotherPhone(false)}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        {applicationType === "Property" ||
                          applicationType === "Floor" || applicationType === "Unit" ? (
                          <div
                            className="form-w-block white-box mr-b30"
                            id="floorAndUnit"
                            onFocus={() => {
                              setNav(4);
                            }}
                          >
                            <div className="row form-inputs">
                              <div className="col-sm-12 col-md-4 col-lg-4">
                                <div className="form-group cus-input-group mr-b30">
                                  <InputDateField
                                    minDate={new Date()}
                                    type={"date"}
                                    label="Desired Move-in Date"
                                    placeholder="Enter Date"
                                    register={register("desiredMoveInDate")}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-12 col-md-4 col-lg-4">
                                <div className="form-group cus-input-group mr-b15">
                                  <SelectField
                                    onChange={(e) => setValue("unit", "")}
                                    notRequired={true}
                                    options={data ? data?.records?.floors : []}
                                    label="By Floor Plan"
                                    register={register("floor")}
                                  />
                                </div>
                              </div>
                              {watch("floor")?.value &&
                                getUnitsList(watch("floor")?.value)?.length >
                                0 && (
                                  <div className="col-sm-12 col-md-4 col-lg-4">
                                    <div className="form-group cus-input-group mr-b15">
                                      <SelectField
                                        notRequired={true}
                                        options={getUnitsList(
                                          watch("floor")?.value
                                        )}
                                        label="By Unit Plan"
                                        register={register("unit")}
                                      />
                                    </div>
                                  </div>
                                )}

                            </div>
                            <div className="row form-inputs">
                              {(watch('floor')?.MinimumRent < 1 || watch('unit')?.MinimumRent < 1) && 
                              <div className="col-sm-12 col-md-4 col-lg-4">
                                 <div className="form-group cus-input-group mr-b30">
                                    <InputCurrencyField
                                      label="Desired Rent"
                                      suffix="$"
                                      register={register("rent")}
                                      notRequired={true}
                                    />
                                  </div>
                              </div>}
                            </div>
                          </div>
                        ) : null}
                        {applicationType == "Office" || applicationType == "Agent" ? (
                          <div
                            className="form-w-block white-box mr-b30"
                            id="agent"
                            onFocus={() => {
                              setNav(1);
                            }}
                          >
                            <div className="row form-inputs">
                              <div className="col-sm-12 col-md-6 col-lg-4">
                                <div className="form-group cus-input-group mr-b15">
                                  <SelectField
                                    options={data ? data?.records.Agents : []}
                                    label="Agent"
                                    register={register("agent")}
                                  />
                                </div>
                              </div>
                              {getValues('Offices')?.length > 0 && (
                                <div className="col-sm-12 col-md-6 col-lg-4">
                                  <div className="form-group cus-input-group mr-b15">
                                    <SelectField
                                      options={data ? data?.records.Offices : []}
                                      label="Office"
                                      register={register("Office")}
                                    />
                                  </div>
                                </div>
                              )}

                              {watch("agent")?.value == 0 && (
                                <div className="col-sm-4">
                                  <div className="form-group cus-input-group mr-b15">
                                    <InputField
                                      label="Other Agent Name"
                                      register={register("otherAgentName")}
                                    />
                                  </div>
                                </div>
                              )}
                              <div className="col-sm-12">
                                <div className="row agent-row">
                                  <div className="col-sm-12 col-md-12 col-lg-12 d-flex align-items-center">
                                    <InputCheckBoxField
                                      notRequired={true}
                                      checked={watch("coOperatingAgentStatus")}
                                      label="Applying with Co-Operating Agent?"
                                      register={register(
                                        "coOperatingAgentStatus"
                                      )}
                                    />
                                  </div>
                                  {watch("coOperatingAgentStatus") && (
                                    <>
                                      <div className="col-sm-12 col-md-6 col-lg-4">
                                        <div className="form-group cus-input-group mr-b0">
                                          <InputField
                                            label="Agent Name"
                                            register={register("coAgentName")}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-sm-12 col-md-6 col-lg-4">
                                        <div className="form-group cus-input-group mr-b0">
                                          <InputField
                                            label="Real Estate Agency"
                                            register={register(
                                              "realEstateAgency"
                                            )}
                                          />
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {applicationType == "Office" || applicationType == "Agent" ? (
                          <div
                            className="form-w-block white-box mr-b30"
                            id="desired"
                            onFocus={() => {
                              setNav(2);
                            }}
                          >
                            <div className="row form-inputs">
                              <div className="col-sm-12 col-md-12 col-lg-12">
                                <div className="form-group cus-input-group mr-b30">
                                  <GoogleAutoComplete
                                    notRequired={true}
                                    groupFieldName={{
                                      street: `address`,
                                      city: `city`,
                                      state: `state`,
                                      state_data: data?.records?.States,
                                    }}
                                    register={register("addressSearch")}
                                    label="Search Desired Property Address"
                                  />
                                </div>
                              </div>
                              <div className="col-sm-12 col-md-6 col-lg-4">
                                <div className="form-group cus-input-group mr-b30">
                                  <InputField
                                    label="Desired Address"
                                    register={register("address")}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-12 col-md-6 col-lg-4">
                                <div className="form-group cus-input-group mr-b30">
                                  <InputField
                                    label="City"
                                    register={register("city")}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-12 col-md-6 col-lg-4">
                                <div className="form-group cus-input-group mr-b15">
                                  <SelectField
                                    options={data ? data?.records.States : []}
                                    label="State"
                                    register={register("state")}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-12 col-md-6 col-lg-4">
                                <div className="form-group cus-input-group mr-b30">
                                  <InputField
                                    type="number"
                                    notRequired={true}
                                    label="Unit No."
                                    register={register("unitNo")}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-12 col-md-6 col-lg-4">
                                <div className="form-group cus-input-group mr-b30">
                                  <InputCurrencyField
                                    label="Desired Rent"
                                    suffix="$"
                                    register={register("rent")}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-12 col-md-6 col-lg-4">
                                <div className="form-group cus-input-group mr-b30">
                                  <InputDateField
                                    minDate={new Date()}
                                    type={"date"}
                                    label="Desired Move-in Date"
                                    placeholder="Enter Date"
                                    register={register("desiredMoveInDate")}
                                  />
                                </div>
                              </div>

                            </div>
                          </div>
                        ) : null}

                        <div
                          className="form-w-block white-box mr-b30"
                          id="logind"
                          onFocus={() => {
                            setNav(3);
                          }}
                        >
                          <div className="row form-inputs">
                            <div className="col-sm-12 col-md-9 col-lg-9">
                              <div className="row">
                                <div className="col-sm-12 col-md-6 col-lg-6">
                                  <div className="form-group cus-input-group mr-b30">
                                    <InputField
                                      label="Email"
                                      // disable={trn_id ? true : false}
                                      register={register("email")}
                                      triggerDependent={
                                        handleDependTriggerCEmail
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6">
                                  <div className="form-group cus-input-group mr-b30">
                                    <InputField
                                      // disable={trn_id ? true : false}
                                      label="Re-Enter Email"
                                      register={register("reEmail")}
                                      triggerDependent={
                                        handleDependTriggerEmail
                                      }
                                    />
                                  </div>
                                </div>
                                {/* //data?.records?.Email_check  */}
                                {(data?.records?.Email_check || props.copyEmailNotUnique) && (
                                  <>
                                    <div className="col-sm-12 col-md-6">
                                      <div className="form-group cus-input-group mb-2">
                                        <InputField
                                          // disable={trn_id ? true : false}
                                          label="Enter Username"
                                          register={register("username", {
                                            required: true,
                                          })}
                                        // triggerDependent={
                                        //   handleDependTriggerEmail
                                        // }
                                        />
                                      </div>
                                    </div>
                                    <span className="mb-2 red">
                                      <strong>Note:</strong> The email indicated
                                      above is registered with another user.
                                      Please specify unique username here.
                                      Username can be an email address, mobile
                                      phone number or any username not already
                                      in use.
                                    </span>
                                  </>
                                )}

                                <div className="row">
                                  <div className="col-sm-12 col-md-6 col-lg-6">
                                    <div className="form-group cus-input-group mr-b30">
                                      <InputPasswordField
                                        hint="Use 8 or more characters with a mix of letters, numbers & special characters"
                                        label="Password"
                                        register={register("password")}
                                        triggerDependent={
                                          handleDependTriggerCPass
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-sm-12 col-md-6 col-lg-6">
                                    <div className="form-group cus-input-group mr-b30">
                                      <InputSimplePassword
                                        triggerDependent={handleDependTrigger}
                                        label="Re-enter Password"
                                        register={register("reEnterPassword")}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6">
                                  <div className="form-group cus-input-group mr-b15">
                                    <SelectField
                                      // notRequired={true}
                                      creatable={true}
                                      options={
                                        data ? data?.records.LeadSource : []
                                      }
                                      label="How did you hear about us?"
                                      register={register("hearAboutUs")}
                                    />
                                  </div>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-12 mt-2">
                                  <div className="apply-check">
                                    <div className="cus-check red-check">
                                      <InputCheckBoxField
                                        notRequired={false}
                                        checked={watch("i_confirm")}
                                        label={`I confirm that I am
                                      applying with ${data?.records?.subscriber_name}`}
                                        register={register("i_confirm")}
                                        errors={formState.errors.i_confirm}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <SupportSection
                        clickHere={clickHere}
                        setClickHere={setClickHere}
                      />
                      <div style={{ height: "20px" }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
};

RegistrationFormBody.propTypes = {};

export default RegistrationFormBody;
