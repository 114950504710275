import {
  AppBar,
  Button,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Slide,
  Toolbar,
  Typography,
  withStyles,
} from "@mui/material";
import React, { useEffect } from "react";
import { FaCheck } from "react-icons/fa";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SuperHeader from "../components/common/superHeader/superHeader";
import CloseIcon from "@mui/icons-material/Close";
import IdentificationDetails from "../components/identification/identification-details/identification-details";
import {
  useGetApplicantStatusQuery,
  useGetIdentificationDataQuery,
  useAddToReviewQMutation,
  useGetIncomeBankingQuery,
} from "../services/identificationApi";
import { toast, ToastContainer } from "react-toastify";
import { useGetMasterRegistrationDataQuery } from "../services/registrationApi";
import View from "./Doc_upload/view";
import { RouteGuardLoginRegistration } from "../PrivateRoute";
import UpdateDesireAddress from "./Update_Address/UpdateDesireAddress";
import SecondaryApp from "./Secondary_App/SecondaryApp";
import {
  formSubmitStatus,
  setViewOnlyFlag,
} from "../store/registration/fromSubmitSlice";
import { RiInformationLine } from "react-icons/ri";
import { protocol } from "../config";
import UpdateDesireAddressProperty from "./Update_Address/UpdateDesireAddressProperty";
import { JSONTree } from "react-json-tree";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Success() {
  const dispatch = useDispatch();
  const [addToReviewQ, result] = useAddToReviewQMutation();

  const [open, setOpen] = React.useState(false);
  const [updateRegisterDialog, setUpdateRegisterDialog] = React.useState(false);
  const [updateRegisterDialog1, setUpdateRegisterDialog1] =
    React.useState(false);
  const [oneStepDialog, setOneStepDialog] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();

  const token = useSelector((state) => state.persistedReducer.loginSlice.token);
  const applicationType = useSelector(
    (state) => state.persistedReducer.loginSlice.applicationType
  );
  console.log(
    "🚀 ~ file: Success.js ~ line 60 ~ Success ~ applicationType",
    applicationType
  );

  const trn_id = useSelector(
    (state) => state.persistedReducer.loginSlice.trnId
  );
  const url = useSelector(
    (state) => state.persistedReducer.loginSlice.urlGeneratedAts
  );
  const getMasterRegistrationdata = useGetMasterRegistrationDataQuery({
    trn_id,
    url,
  });

  console.log("GET MASTER REGISTER DATA:81", getMasterRegistrationdata);
  let identificationData = useGetIdentificationDataQuery(trn_id);
  let IncomeBankData  = useGetIncomeBankingQuery(trn_id);
  let { data, isFetching, isLoading, isError, isSuccess, refetch } =
    useGetApplicantStatusQuery(trn_id);
  console.log("IncomeBankData",  IncomeBankData?.data?.employment_details?.employer?.[0]?.files != null);

  useEffect(() => {
    console.log("SUCCESS:71");
    dispatch(setViewOnlyFlag(false));
    if (!token) {
      navigate("/login");
    }
    refetch();
  }, []);

  if (isLoading)
    return (
      <Loader
        className="center-screen"
        type="Circles"
        color="#00BFFF"
        height={100}
        width={100}
        radius={200}
        timeout={3000} //3 secs
      />
    );

  const addToReviewQfun = async () => {
    const payload = {
      applicationnumber: data?.records?.applicationID,
      isInReview:1
    }
    const response = await toast.promise(addToReviewQ(payload), {
      pending: "Saving",
      error: "Saving failed 🤯",
    });
    if (response) {
      return "Done!!"
    }

  }

  return (
    <div className="success-container">
      <SuperHeader />
      <section className="top-white-row">
        <div className="container">
          <div className="row align-items-center">
            <div className="col col-sm-6">
              <div className="w-r-logo">
                <img
                  src={`${getMasterRegistrationdata?.data?.records?.subrciber_logo}`}
                  className="w-r-logo-img"
                  alt=""
                  title=""
                />
                <span className="res-company-name">
                  {getMasterRegistrationdata?.data?.records?.subscriber_name}
                </span>
              </div>
            </div>
            <div className="col col-sm-6">
              <div
                className="t-w-appid text-right d-flex"
                style={{ justifyContent: "end" }}
              >
                <h5 className="mr-r10">
                  Name:{" "}
                  <span>
                    {" "}
                    {
                      identificationData?.data?.applicant?.first_name
                    }{" "}
                    {identificationData?.data?.applicant?.last_name}
                  </span>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <main className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div>
              <>
                <div className="d-flex justify-content-center m-t-20">
                  <div className="success-circle">
                    <FaCheck className="check-icon" size={30} />
                  </div>
                </div>
                <div>
                  <h3 className="congrats text-center">Congratulations</h3>
                </div>
                <div className="d-flex justify-content-center m-t-20">
                  <div className="application-container">
                    <h6 className="text-center status-text">Application ID</h6>
                    <span className="count">
                      {data?.records?.applicationID}
                    </span>
                  </div>
                  <div className="application-container">
                    <h6 className="text-center status-text">
                      Application Status
                    </h6>
                    <span className="count">
                      {data?.records?.application_status}
                    </span>
                  </div>
                </div>
              </>
              {/* data?.records?.secondary_status */}
              {data?.records?.secondary_status && (
                <>
                  {data?.records?.secondary_complete === false && (
                    <div className="d-flex justify-content-center mt-2 ">
                      <RiInformationLine size={30} fill="#FF8585" />
                    </div>
                  )}
                  <div className="d-flex justify-content-center align-items-center flex-column mt-2">
                    {data?.records?.secondary_complete === false && (
                      <h5 style={{ color: "red" }}>
                        Application Incomplete! You are required to complete a
                        supplemental application regarding criminal background
                      </h5>
                    )}
                    <h5
                      onClick={() => setOneStepDialog(true)}
                      className="cursor-pointer"
                      style={{
                        color: data?.records?.secondary_complete
                          ? "#8cc474"
                          : "red",
                      }}
                    >
                      Please complete your &nbsp;
                      <span style={{ textDecoration: "underline" }}>
                        {data?.records?.secondary_complete
                          ? "Secondary Application Completed"
                          : "Secondary Application"}
                      </span>
                    </h5>
                  </div>
                  <br />
                </>
              )}
              {data?.records?.document_status === false && (
                <>
                  <div className="d-flex flex-column mt-2  justify-content-center">
                    <h5 className="doc-upload-text text-center cursor-pointer">
                      One more step
                    </h5>
                    <p className="text-center">
                      We just need to have you complete uploading any missing
                      documents so that we can verify your identity and income,
                      which is required to process your application. Click the
                      link below to navigate to the document upload section
                      where you can review what you've already uploaded, find
                      relevant instructions, and upload any missing or required
                      documents.
                    </p>
                  </div>
                  <div style={{ height: "10px" }} />
                  <div className="d-flex mt-2  justify-content-center">
                    <h5
                      style={{ color: "red", flex: 0.6 }}
                      className="text-center grey"
                    >
                      Application Incomplete! You have not yet provided required
                      proof documents. <br />
                      <div style={{ height: "15px" }} />
                      Complete your{" "}
                      <span
                        onClick={handleClickOpen}
                        className="doc-upload-text cursor-pointer"
                        style={{ color: "red" }}
                      >
                        Document Upload
                      </span>{" "}
                    </h5>
                  </div>
                </>
              )}

              <div style={{ height: "10px" }} />
              {data?.records?.document_status && (
                <div className="d-flex mt-2 justify-content-center">
                  <div style={{ height: "15px" }} />
                  <h5 className="text-center grey">
                    View your{" "}
                    <span
                      onClick={handleClickOpen}
                      className="doc-upload-text cursor-pointer"
                    >
                      Document Upload
                    </span>{" "}
                  </h5>
                </div>
              )}
              {applicationType == "Office" || applicationType == "Agent" ? (
                <div className="d-flex mt-2 justify-content-center">
                  <h5 className="text-center grey">
                    View/Update your{" "}
                    <span
                      onClick={() => setUpdateRegisterDialog(true)}
                      className="doc-upload-text cursor-pointer"
                    >
                      Desired Address
                    </span>{" "}
                  </h5>
                </div>
              ) : (
                <div className="d-flex mt-2 justify-content-center">
                  <h5 className="text-center grey">
                    View/Update your{" "}
                    <span
                      onClick={() => setUpdateRegisterDialog1(true)}
                      className="doc-upload-text cursor-pointer"
                    >
                      Desired Floor/Unit
                    </span>{" "}
                  </h5>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* <Button variant="outlined" onClick={handleClickOpen}>
          Open full-screen dialog
        </Button> */}
      </main>

      <footer className="footer">
        <button
          onClick={() => {
            navigate("/viewconsent");
          }}
          className="btn btn-outline-info ml-4"
        >
          <span className="view-consent-text">View Consent</span>
        </button>
        <button onClick={() => navigate("/view")} className="btn btn-info ml-4">
          <span className="view-application-text">View Application</span>
        </button>
      </footer>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        {/* <JSONTree data={IncomeBankData?.data?.employment_details?.employer}/> */}
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              // onClick={handleClose}
              onClick={() => {

                handleClose();
                if (identificationData?.data?.identification_data?.file != null || 
                  identificationData?.data?.ssn_data?.file != null ||  
                  IncomeBankData?.data?.employment_details?.employer?.[0]?.files?.length > 0  ||
                  IncomeBankData?.data?.banking_details?.banking?.[0]?.files?.length > 0 ||
                  IncomeBankData?.data?.other_documents?.[0]?.files?.length > 0||
                  IncomeBankData?.data?.other_income_details?.otherincome?.[0]?.files?.length > 0
                ) {
                  debugger;
                  addToReviewQfun();
                } else {
                  console.log("Condition not met, function not called.");
                }

              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Docupload
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent dividers={true}>
          <View setOpen={setOpen} />
        </DialogContent>
      </Dialog>
      <Dialog
        fullScreen
        open={updateRegisterDialog1}
        onClose={() => setUpdateRegisterDialog1(false)}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setUpdateRegisterDialog1(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Desired Floor/Unit
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent dividers={true}>
          <UpdateDesireAddressProperty
            floors={getMasterRegistrationdata?.data?.records?.floors}
            units={getMasterRegistrationdata?.data?.records?.units}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        fullScreen
        open={updateRegisterDialog}
        onClose={() => setUpdateRegisterDialog(false)}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setUpdateRegisterDialog(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Desired Address
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent dividers={true}>
          <UpdateDesireAddress />
        </DialogContent>
      </Dialog>
      <Dialog
        fullScreen
        open={oneStepDialog}
        onClose={() => setOneStepDialog(false)}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setOneStepDialog(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Secondary App
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent dividers={true}>
          <SecondaryApp
            setOneStepDialog={setOneStepDialog}
            fieldset={
              data?.records?.secondary_complete
                ? data?.records?.secondary_complete
                : false
            }
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default Success;
