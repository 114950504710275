import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useUploadFileServiceMutation } from "../../../services/identificationApi";
import { sideNavAction } from "../../../store/identification/sideMenuSlice";
import Dropzone from "react-dropzone";
import { FaFileUpload } from "react-icons/fa";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import "./style.css";

import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

const CustomUpload = (props) => {
  const { watch } = useForm();

  const trn_id = useSelector(
    (state) => state.persistedReducer.loginSlice.trnId
  );
  const dispatch = useDispatch();
  const [uploadFileRequest, result] = useUploadFileServiceMutation();

  const [documentId, setDocumentId] = useState(props.documentId);
  // const [file, setFile] = useState('');
  const [disableFlag, setDisabledFlag] = useState(false);

  const onChangeFile = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    reader.readAsText(e.target.files[0]);
    let data = e.target.files[0];
    reader.onload = () => {
      if (reader.readyState === 2) {
        // setFile(reader.result);

        uploadFile(data);
      }
    };
  };

  const uploadFile = (file) => {
    setDisabledFlag(true);
    var formData = new FormData();
    for (let f of file) {
    formData.append("files", f);
    }
    formData.append("trn_id", trn_id);
    formData.append("document_id", props.documentId);
    formData.append("source_id", props.SourceId); //7929
    formData.append("allowOfferLetter", props?.allowOfferLetter);
    console.log(formData);

    uploadFileRequest(formData).then((response) => {

      if (response?.error?.data?.status == 400) {
        toast.error(response?.error?.data?.message)
      }
      console.log("Upload File Response Data", response);
      if (response?.data?.success === true) {
        dispatch(
          sideNavAction.uploadPetImageId({
            index: props?.petIndex,
            id: response?.data?.records?.id,
            file: response?.data?.records?.blob_path,
            type: "UPLOAD",
          })
        );
      }

    }).finally(() => {
      setTimeout(() => setDisabledFlag(false), 5000);
    });
  };

  return (
    <>
      <div className="App">
        <Dropzone
          disabled={
            props?.disable
              ? props.disable
              : disableFlag
                ? true
                : false
          }
          multiple={props?.multiple ? false : true}
          onDropRejected={(v) => {
            for (let file of v) {
              console.log("🚀 ~ file: CustomUpload.js:68 ~ file", file)
              for (let error of file.errors) {
                if (error.code == 'file-too-large') {
                  toast.warn('File is larger than 10MB');
                }
              }
            }
            console.log("🚀 ~ file: CustomUpload.js:67 ~ v", v)
          }}
          maxSize={10485760}
          onDrop={(file) => {
            // for (let f of file) {
              uploadFile(file);
            // }
          }}
          accept={
            props.onlyPdf == true
              ? "application/pdf"
              : "image/*,application/pdf"
          }
        >
          {({
            getRootProps,
            getInputProps,
            isDragActive,
            isDragAccept,
            isDragReject,
          }) => {
            const additionalClass = isDragAccept
              ? "accept"
              : isDragReject
                ? "reject"
                : "";

            return (
              <div
                {...getRootProps({
                  className: `dropzone ${additionalClass}`,
                })}
              >
                <input
                  id={props?.register ? props.register.name : ""}
                  {...getInputProps()}
                />
                <span>
                  <FaFileUpload size={40} />
                </span>
                <p style={{ padding: "4px" }}>File Upload</p>
              </div>
            );
          }}
        </Dropzone>
      </div>
      {result.status === "pending" && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      {/* <div className={`ssn-btn ${props.className}`}>
        <div className="browse-btn">
          {/* <input
            disabled={props?.disable ? props.disable : false}
            type="file"
            onChange={onChangeFile}
            className="cursor-pointer"
          />
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3 19H21V21H3V19ZM13 5.828V17H11V5.828L4.929 11.9L3.515 10.486L12 2L20.485 10.485L19.071 11.899L13 5.83V5.828Z"
              fill="white"
            />
          </svg> 
          {props?.label}
        </div>
      </div> */}
    </>
  );
};

export default CustomUpload;
